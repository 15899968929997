import Grid from "app/components/Grid";
import PropTypes from "prop-types";
import React from "react";
import styled, { withTheme } from "styled-components";
import { flexbox, grid, space } from "styled-system";

const Wrapper = styled(Grid).attrs(({ gridGap, gridTemplateColumns }) => {
  return {
    width: "100%",
    gridGap: gridGap || "24px",
    gridTemplateColumns: gridTemplateColumns || [
      "repeat(1, 1fr)",
      "repeat(1, 1fr)",
      "repeat(1, 1fr)",
      "repeat(2, 1fr)",
      "repeat(2, 1fr)",
      "repeat(3, 1fr)",
    ],
  };
})`
  ${flexbox}
  ${space}
  ${grid}
`;

const CardList = ({ cards, renderCard, sentinel, ...props }) => {
  return (
    <Wrapper {...props}>
      {cards &&
        cards.map((cardData, index) =>
          renderCard({
            cardData,
            index,
          })
        )}
      {sentinel}
    </Wrapper>
  );
};

CardList.defaultProps = {
  sentinel: null,
};

CardList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderCard: PropTypes.func.isRequired,
  sentinel: PropTypes.element,
};

export default withTheme(CardList);
