import React, { useState, createRef, useEffect } from "react";
import Flex from "app/components/Flex";
import PropTypes from "prop-types";
import { StyledInput } from "./components";

const ALPHANUMERIC_REGEX = /^[a-z0-9]+$/;

export const CodeInput = ({
  values,
  autoFocus,
  disabled,
  error,
  name,
  onChange,
  placeholder,
  required,
  manageFocus,
}) => {
  const [inputRefs, setInputRefs] = useState([]);
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(
    function initializeInputRefs() {
      setInputRefs(refs =>
        Array(values.length)
          .fill()
          .map((_, i) => refs[i] || createRef())
      );
    },
    [values]
  );

  useEffect(
    function autoFocusFirstInputRef() {
      if (autoFocus) {
        const inputRef = inputRefs[0];
        if (inputRef?.current?.focus && !inputRef.current.value) {
          inputRef.current.focus();
        }
      }
    },
    [autoFocus, inputRefs]
  );

  return (
    <Flex alignItems="center">
      {values.map((v, i) => (
        <StyledInput
          fontSize={8}
          fontWeight="bold"
          width="114px"
          height="114px"
          mr={3}
          disabled={disabled}
          error={error}
          inputMode="numeric"
          ref={inputRefs[i]}
          key={i}
          name={name}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
          onChange={event => {
            const eventValue = event.target.value;
            // in the case of an autocomplete or copy and paste
            if (eventValue.length > 2) {
              // see if we can use the string to fill out our values
              if (
                eventValue.length === values.length &&
                eventValue.match(ALPHANUMERIC_REGEX)
              ) {
                onChange({ values: eventValue.split(""), event });
              }
              return;
            }
            // value was deleted
            if (eventValue === "") {
              const newValues = values.slice();
              newValues[i] = "";
              onChange({ values: newValues, event });
              return;
            }
            // we want to override the input value with the last digit typed
            const currentValue = values[i];
            let newValue = eventValue;
            if (currentValue[0] === eventValue[0]) {
              // eslint-disable-next-line prefer-destructuring
              newValue = eventValue[1];
            } else if (currentValue[0] === eventValue[1]) {
              [newValue] = eventValue;
            }
            // only fire a change event if the new value is lowercased alphanumeric
            if (newValue.match(ALPHANUMERIC_REGEX)) {
              const newValues = values.slice();
              newValues[i] = newValue;
              onChange({ values: newValues, event });
              // tab to next pin code input if we aren't at end already
              if (manageFocus && i < values.length - 1) {
                const inputRef = inputRefs[i + 1];
                if (inputRef?.current?.focus) {
                  inputRef.current.focus();
                }
              }
            }
          }}
          onKeyDown={event => {
            // if we see a backspace/delete and the input is empty, transfer focus backward
            if (
              manageFocus &&
              event.key === "Backspace" &&
              values[i] === "" &&
              i > 0
            ) {
              const inputRef = inputRefs[i - 1];
              if (inputRef?.current?.focus) {
                inputRef.current.focus();
              }
            }
          }}
          placeholder={hasFocus ? "" : placeholder}
          required={required}
          type="text"
          value={values[i]}
        />
      ))}
    </Flex>
  );
};

CodeInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  error: false,
  name: null,
  onChange: () => {},
  placeholder: "",
  required: false,
  manageFocus: true,
  values: ["", "", "", ""],
};

CodeInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  manageFocus: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.string),
};

export default CodeInput;
