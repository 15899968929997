import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { compose } from "redux";
import PropTypes from "prop-types";
import { queuePartyAction, leaveQueueAction } from "modules/party";
import { hideErrorAction } from "modules/error";
import Flex from "app/components/Flex";
import { H3, P1 } from "app/components/Typography";
import Dot from "app/components/Dot";
import Div from "app/components/Div";
import { PARTY_STATUSES } from "constants/index";
import PartyModal from "./PartyModal";

const WaitingRoom = ({
  classData,
  hideError,
  isAnonymous,
  isRequestingClassData,
  userPartyData,
  partyId,
  queueParty,
  error,
  leaveQueue,
  setPartyId,
}) => {
  const history = useHistory();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(error != null);
  const [isPartyEndedModalOpen, setIsPartyEndedModalOpen] = useState(
    userPartyData.status === PARTY_STATUSES.ENDED
  );

  useEffect(() => {
    if (
      partyId &&
      (!userPartyData.status || userPartyData.status === PARTY_STATUSES.LEFT) &&
      !isAnonymous
    ) {
      queueParty({ pid: partyId });
    }
  }, [isAnonymous, partyId, queueParty, userPartyData.status]);

  useEffect(() => {
    setIsErrorModalOpen(error != null);
  }, [error]);

  useEffect(() => {
    setIsPartyEndedModalOpen(userPartyData.status === PARTY_STATUSES.ENDED);
  }, [userPartyData.status]);

  if (isRequestingClassData) {
    return null;
  }

  if (userPartyData.status === PARTY_STATUSES.APPROVED && classData?.id) {
    return (
      <Redirect
        to={{
          pathname: `/class/${classData.id}`,
          state: { refComponent: "WaitingRoom" },
        }}
      />
    );
  }

  return (
    <Div
      position="absolute"
      height="100%"
      width="100%"
      bg="rgba(34, 34, 34, 0.8)"
      backdropFilter="blur(30px)"
      zIndex={1}
    >
      <Flex
        height="100%"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <H3 color="white">Waiting For Host To Accept</H3>
        <P1 pt={3} color="monochrome.3">
          Please stand by for your host to accept your invitation
        </P1>
        <Flex pt={5}>
          <Dot bg="white" mx={2} />
          <Dot bg="white" mx={2} />
          <Dot bg="white" mx={2} />
          <Dot bg="white" mx={2} />
        </Flex>
        <P1
          as="button"
          background="none"
          border="none"
          borderBottom="1px solid"
          borderColor="monochrome.4"
          mt={6}
          color="monochrome.4"
          onClick={() => {
            leaveQueue({ pid: partyId });
            setPartyId(null);
            history.push("/party");
          }}
        >
          Leave Queue
        </P1>
      </Flex>
      <PartyModal
        isOpen={error && isErrorModalOpen}
        toggleModal={() => {
          setPartyId(null);
          hideError();
          history.push("/party");
          setIsErrorModalOpen(false);
        }}
        handleClick={() => {
          hideError();
          setPartyId(null);
          history.push("/party");
        }}
        content={{
          headerTitle: "Looks like something went wrong",
          body: error?.message,
          callToAction: "Go Back",
        }}
      />
      <PartyModal
        classData={classData}
        isOpen={isPartyEndedModalOpen}
        toggleModal={() => {
          setPartyId(null);
          history.push("/party");
          setIsPartyEndedModalOpen(false);
        }}
        handleClick={() => {
          history.push("/checkout");
        }}
        content={{
          headerTitle: "Party has ended",
          // eslint-disable-next-line no-multi-str
          body:
            "Enjoyed the class? Subscribe to STEEZY to check out more classes and continue dancing!",
          callToAction: "Subscribe to Steezy",
        }}
      />
    </Div>
  );
};

const mapStateToProps = ({ firebase, user, error }, { partyId }) => {
  const userPartyData =
    partyId === user.private.party?.id ? user.private.party : {};
  const isRequestingClassData =
    firebase.requesting[`classes_details/${userPartyData?.classId}`];
  const classData = getVal(
    firebase,
    `data/classes_details/${userPartyData?.classId}`
  );

  let errorCopy = error && { ...error };
  if (userPartyData?.status === PARTY_STATUSES.REJECTED) {
    errorCopy = {
      message:
        "We're sorry, but the host has declined your request to join the party.",
    };
  }

  return {
    classId: userPartyData?.classId,
    classData,
    isRequestingClassData,
    userPartyData,
    error: errorCopy,
  };
};

const mapDispatchToProps = {
  queueParty: queuePartyAction,
  leaveQueue: leaveQueueAction,
  hideError: hideErrorAction,
};

WaitingRoom.defaultProps = {
  isAnonymous: true,
  userPartyData: {},
  error: null,
};

WaitingRoom.propTypes = {
  classData: PropTypes.shape({}).isRequired,
  hideError: PropTypes.func.isRequired,
  isAnonymous: PropTypes.bool,
  isRequestingClassData: PropTypes.bool.isRequired,
  userPartyData: PropTypes.shape({}),
  partyId: PropTypes.string.isRequired,
  queueParty: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
  leaveQueue: PropTypes.func.isRequired,
  setPartyId: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(({ classId }) => [`classes_details/${classId}`])
)(WaitingRoom);
