import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import queryString from "query-string";
import styled from "styled-components";
import _ from "lodash";
import { useFirebaseConnect } from "react-redux-firebase";
import Flex from "app/components/Flex";
import Button from "app/components/Button";
import Div from "app/components/Div";
import { H1, H2, H3, P1 } from "app/components/Typography";
import { SignUpModal } from "app/components/SignUpModal";
import Img from "app/components/Img";
import ResponsiveImg from "app/components/Img/Responsive";
import { ClassList } from "app/components/ClassList";
import { ReactComponent as STEEZYPartyHeader } from "app/assets/svg/steezy-party-header.svg";
import {
  Loop,
  Speed,
  Mirror,
  View,
  Friends,
  MicOn,
  Camera,
} from "app/components/Icon";
import { IS_MOBILE_ONLY, IS_IOS } from "constants/index";
import { useLocation } from "react-router-dom";
import env from "helpers/env";
import { Routes } from "constants/routes";
import CodeInput from "./CodeInput";
import WaitingRoom from "./WaitingRoom";
import { StepBlock, FeatureListItem } from "./components";
import { useGetUserIsAnonymous } from "../../../modules/selectors";

const HeaderSpan = styled.span`
  &:after {
    content: "\\a";
    white-space: pre;
  }
`;

const PARTY_FEATURES = [
  {
    index: 1,
    icons: [Loop, Speed],
    markup:
      "Synced speed, looping, and class time for all guests in your party",
  },
  {
    index: 2,
    icons: [Mirror, View],
    markup:
      "Custom control over volume, mirroring, and front + back camera views for each guest",
  },
  {
    index: 3,
    icons: [Friends],
    iconMarkup: "(10)",
    markup: "Invite up to 10 friends into your party",
  },
  {
    index: 4,
    icons: [MicOn, Camera],
    markup: "Video chat with your friends easily",
  },
];

const RECOMMENDED_PARTY_CLASSES = [697, 507];

const Party = () => {
  const location = useLocation();
  const isAnonymous = useGetUserIsAnonymous(true);

  useFirebaseConnect(
    RECOMMENDED_PARTY_CLASSES.map(classId => ({
      path: `classes_details/${classId}`,
    }))
  );
  const recommendedPartyClasses = Object.values(
    useSelector(({ firebase }) =>
      _.filter(
        firebase.data.classes_details,
        _class =>
          !!_class && RECOMMENDED_PARTY_CLASSES.includes(parseInt(_class.id))
      )
    )
  );
  const navbarHeight = useSelector(
    ({ components }) => components.Navbar.height
  );
  const queryObj = queryString.parse(location.search);
  const { pid: partyIdParam } = queryObj;
  const [partyId, setPartyId] = useState(null);
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [isInputValid, setIsInputValid] = useState(partyIdParam?.length === 4);
  const [hasInputError, setHasInputError] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(isAnonymous);
  const isWaitingRoomOpen = Boolean(partyIdParam || partyId);

  const handleSubmit = () => {
    if (isInputValid) {
      setPartyId(inputValues.join(""));
    } else {
      setHasInputError(true);
    }
  };

  const handleEnterKey = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  };

  useEffect(() => {
    if (partyIdParam) {
      setPartyId(partyIdParam);
    }
    setIsInputValid(inputValues.every(v => v));
  }, [partyIdParam, inputValues]);

  useEffect(() => {
    setIsSignUpModalOpen(isAnonymous);
  }, [isAnonymous]);

  if (IS_MOBILE_ONLY) {
    return (
      <>
        <Flex backgroundColor="monochrome.8" p={3} flexDirection="column">
          <P1 color="monochrome.0" mb={2}>
            Only Available On
          </P1>
          <H2 color="monochrome.0">STEEZY Desktop App</H2>
        </Flex>
        <Flex alignItems="center" px={2} py={4} backgroundColor="primary.0">
          <Img
            src={`${env(
              "PUBLIC_ASSET_PATH"
            )}/party/splash-page-introduction.png?w=350`}
            maxWidth="100%"
            height="auto"
          />
        </Flex>
        <Flex px={3} py={4} flexDirection="column">
          <H3 mb={3}>To Use This Feature, Please Open Steezy On Desktop</H3>
          <P1 mb={4}>
            In order to use STEEZY Party, you must sign in on a desktop device.
            Get the STEEZY App available in IOS and Android now!
          </P1>
          <Button
            onClick={() => {
              if (IS_IOS) {
                window.open(
                  "https://itunes.apple.com/us/app/steezy-studio/id1296001664",
                  "blank"
                );
              } else {
                window.open(
                  "https://play.google.com/store/apps/details?id=co.steezy.app",
                  "blank"
                );
              }
            }}
          >
            Get the Steezy App
          </Button>
        </Flex>
      </>
    );
  }

  return (
    <Div
      position="relative"
      overflow={isWaitingRoomOpen ? "hidden" : "auto"}
      height={isWaitingRoomOpen ? `calc(100vh - ${navbarHeight}px)` : "100%"}
      onKeyDown={handleEnterKey}
    >
      {isWaitingRoomOpen && (
        <WaitingRoom
          isAnonymous={isAnonymous}
          partyId={partyId}
          setPartyId={setPartyId}
        />
      )}
      <SignUpModal
        isOpen={isAnonymous && isSignUpModalOpen}
        closeModal={() => setIsSignUpModalOpen(false)}
        routeToRedirectAfterClose={
          isAnonymous
            ? Routes.library__classes
            : `${Routes.party}?pid=${partyId || ""}`
        }
        eventAttributes={{ variant: "party_guest" }}
      />
      {!isSignUpModalOpen && (
        <Flex width="100%" flexDirection="column">
          <Flex
            pt={6}
            pb={5}
            flexDirection="column"
            alignItems="center"
            backgroundColor="black"
          >
            <Div as={STEEZYPartyHeader} color="white" />
            <H3 pt={4} color="monochrome.4">
              Enter Your 4-Digit Room code
            </H3>
            <Div pt={4} pb={4} color="white">
              <CodeInput
                autoFocus
                values={inputValues}
                error={hasInputError}
                onChange={({ values }) => {
                  setHasInputError(false);
                  setInputValues(values);
                }}
              />
            </Div>
            <Button
              position="relative"
              width="250px"
              p={["14px 78px"]}
              onClick={handleSubmit}
            >
              <Div position="absolute">Join Party</Div>
            </Button>
          </Flex>
          <Flex
            flexDirection="column"
            position="relative"
            backgroundColor="blue"
            height={{ md: "300px", lg: "400px" }}
            py={4}
            px={{ md: 4, lg: 6 }}
            mb="15%"
          >
            <H1 py={4} mb={3} color="white" fontSize={{ md: 6, lg: 7 }}>
              A New Way To Dance With Friends
            </H1>
            <Flex
              backgroundColor="white"
              boxShadow="0px 4px 20px rgba(0, 0, 0, 0.1)"
              borderRadius="16px"
              p={4}
            >
              <Flex
                flexDirection="column"
                flex={1}
                pt={{ md: 2, lg: 4 }}
                mr={{ _: 4, xl: 6 }}
                minWidth={{ _: "300px", md: "400px" }}
              >
                <H2 color="black" pb={3} fontSize={{ md: "24px", lg: "28px" }}>
                  <HeaderSpan>Learn to dance</HeaderSpan>together on STEEZY
                </H2>
                <P1 fontSize={{ md: "12px", lg: "16px" }} pb={3}>
                  Video chat with up to 10 friends while taking any class on
                  STEEZY. Classes are automatically synced for all guests.
                </P1>
                <P1 fontSize={{ md: "12px", lg: "16px" }} pb={3}>
                  Party is a great way to give and get feedback, learn the
                  latest moves and routines with your squad, and connect with
                  your dancer friends around the world. Check out the guide
                  below to learn how to use the feature, step-by-step.
                </P1>
              </Flex>
              <Flex alignItems="center">
                <ResponsiveImg
                  srcSet={`
                      ${env(
                        "PUBLIC_ASSET_PATH"
                      )}/party/splash-page-introduction.png?w=823 823w,
                      ${env(
                        "PUBLIC_ASSET_PATH"
                      )}/party/splash-page-introduction.png?w=723 723w,
                      ${env(
                        "PUBLIC_ASSET_PATH"
                      )}/party/splash-page-introduction.png?w=350 350w`}
                  sizes="
                    (min-width: 1440px) 823px,
                    (min-width: 1170px) 723px,
                    (min-width: 768px) 350px"
                  maxWidth="100%"
                  height="auto"
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex px={{ md: 4, lg: 6 }} flexDirection="column">
            <H1 mt={5} mb={4}>
              How To Create A Party
            </H1>
            <Flex
              justifyContent="space-between"
              flexDirection={{ _: "column", lg: "row" }}
            >
              <StepBlock
                stepNumber={1}
                stepText="Select a Class"
                src={`${env(
                  "PUBLIC_ASSET_PATH"
                )}/party/splash-page-step-1.png?w=500`}
                placeholderSrc={`${env(
                  "PUBLIC_ASSET_PATH"
                )}/party/splash-page-step-1.png?blur=200&px=16&auto=format`}
              />
              <StepBlock
                stepNumber={2}
                stepText="Create a Party"
                src={`${env(
                  "PUBLIC_ASSET_PATH"
                )}/party/splash-page-step-2.png?w=500`}
                placeholderSrc={`${env(
                  "PUBLIC_ASSET_PATH"
                )}/party/splash-page-step-2.png?blur=200&px=16&auto=format`}
              />
              <StepBlock
                stepNumber={3}
                stepText="Invite Friends"
                src={`${env(
                  "PUBLIC_ASSET_PATH"
                )}/party/splash-page-step-3.png?w=500`}
                placeholderSrc={`${env(
                  "PUBLIC_ASSET_PATH"
                )}/party/splash-page-step-3.png?blur=200&px=16&auto=format`}
              />
            </Flex>
          </Flex>
          <Flex px={{ md: 4, lg: 6 }} flexDirection="column">
            <H1 mt={5} mb={4}>
              Party Features
            </H1>
            <Flex flexDirection={{ _: "column", lg: "row" }}>
              <Flex alignItems="center" mb={{ md: 3, _: 0 }}>
                <ResponsiveImg
                  srcSet={`
                        ${env(
                          "PUBLIC_ASSET_PATH"
                        )}/party/splash-page-features.png?w=800 800w,
                        ${env(
                          "PUBLIC_ASSET_PATH"
                        )}/party/splash-page-features.png?w=500 500w`}
                  sizes="
                      (min-width: 1440px) 800px,
                      (min-width: 1170px) 500px,
                      100vw"
                  maxWidth="100%"
                  height="auto"
                />
              </Flex>
              <Flex flexDirection="column" px={{ md: 0, lg: 5 }} py={3}>
                <H3>Current Features Available On Party</H3>
                <Flex as="ul" flexDirection="column" p={0}>
                  {PARTY_FEATURES.map(featureProps => (
                    <FeatureListItem {...featureProps} />
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex px={{ md: 4, lg: 6 }} mb={6} flexDirection="column">
            <H1 mt={{ md: 4, lg: 5 }} mb={2}>
              Start Your First Party!
            </H1>
            <H3
              mb={4}
              color="monochrome.4"
              fontWeight="normal"
              letterSpacing="0.01em"
            >
              Check out some of our favorite classes to take with friends.
            </H3>
            {recommendedPartyClasses.length && (
              <ClassList
                classes={recommendedPartyClasses}
                selectedFrom="Party"
                justifyContent="space-between"
              />
            )}
          </Flex>
        </Flex>
      )}
    </Div>
  );
};

const mapStateToProps = ({ auth }) => ({
  isAnonymous: auth.isAnonymous,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Party);
