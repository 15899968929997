import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { border, layout } from "styled-system";

const ResponiveImgWrapper = styled.img`
  ${layout}
  ${border}
`;

const ResponiveImg = ({ placeholderSrc, srcSet, sizes, ...rest }) => {
  return <ResponiveImgWrapper srcSet={srcSet} sizes={sizes} {...rest} />;
};

ResponiveImg.defaultProps = {
  placeholderSrc: null,
};

ResponiveImg.propTypes = {
  placeholderSrc: PropTypes.string,
  srcSet: PropTypes.string.isRequired,
  sizes: PropTypes.string.isRequired,
};

export default ResponiveImg;
