import CardList from "app/components/CardList";
import ClassCard from "app/components/ClassCard";
import React from "react";
import { Class, StudioClass } from "services/graphql";
import env from "helpers/env";
import { useHistory } from "react-router";
import { Routes } from "constants/routes";
import Div from "../Div";
import { ThumbnailContainer } from "../ClassCard/Card";
import { ThumbnailWrapper, Wrapper } from "../ClassCard/Card/styles";
import Thumbnail from "../Thumbnail";
import Button from "../Button";
import { P1 } from "../Typography";

interface ClassListProps {
  classes: Array<Partial<StudioClass> | Partial<Class>>;
  selectedFrom?: string;
  sentinel?: React.ReactNode;
  isSubscribeModalShowing?: boolean;
}

export const ClassList = ({
  classes,
  selectedFrom = "ClassList",
  sentinel,
  isSubscribeModalShowing = false,
  ...rest
}: ClassListProps) => {
  const history = useHistory();
  return (
    <CardList
      {...rest}
      cards={classes}
      sentinel={sentinel}
      renderCard={({
        cardData,
      }: {
        cardData: Partial<StudioClass> | Partial<Class>;
      }) => {
        if (!cardData) {
          return <></>;
        }

        if (cardData.type === "upgrade-card") {
          const thumbnailSlug = "dustin-ripkens-steezy-sweat-with-dustin-3";
          return (
            <Div width="100%">
              <Wrapper>
                <ThumbnailContainer>
                  <ThumbnailWrapper>
                    <Thumbnail
                      placeholderSrc={`${env(
                        "PUBLIC_ASSET_PATH"
                      )}/class-thumbnails/${thumbnailSlug}-basic.jpg?blur=200&px=16&auto=format`}
                      src={`${env(
                        "PUBLIC_ASSET_PATH"
                      )}/class-thumbnails/${thumbnailSlug}-basic.jpg?w=600`}
                    />
                  </ThumbnailWrapper>
                  <Div
                    position="absolute"
                    bottom="0"
                    left="0"
                    p="16px"
                    color="white"
                    width="100%"
                    background="linear-gradient(rgba(0,0,0,0) 0%,rgb(34,34,34) 100%)"
                  >
                    <P1 mb="8px" fontWeight="bold">
                      Unlock hundreds more classes
                    </P1>
                    <Button
                      p="8px"
                      onClick={() => history.push(Routes.checkout)}
                    >
                      Upgrade Now
                    </Button>
                  </Div>
                </ThumbnailContainer>
              </Wrapper>
            </Div>
          );
        }

        return (
          <ClassCard
            key={cardData.id}
            data={cardData}
            selectedFrom={selectedFrom}
            isSubscribeModalShowing={isSubscribeModalShowing}
          />
        );
      }}
    />
  );
};
