import React from "react";
import Flex from "app/components/Flex";
import { Close } from "app/components/Icon";
import IconButton from "app/components/Button/IconButton";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant } from "styled-system";
import colors from "theme/colors";

export const ModalWrapper = ({ children }) => (
  <Flex justifyContent="center" width="100%">
    <Flex
      flexDirection="column"
      borderRadius="10px"
      overflow="hidden"
      maxWidth="670px"
      minWidth={{ _: 0, sm: "435px" }}
      minHeight="370px"
    >
      {children}
    </Flex>
  </Flex>
);

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const Header = ({ children, toggleModal, ...rest }) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    width="100%"
    {...rest}
  >
    <div>{children}</div>
    {toggleModal && (
      <IconButton
        id="close-button"
        data-test-id="header-modal-close-button"
        width="20px"
        height="20px"
        Icon={Close}
        onClick={toggleModal}
      />
    )}
  </Flex>
);

Header.defaultProps = {
  toggleModal: null,
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  toggleModal: PropTypes.func,
};

export const ModalHeader = styled(Header)(
  variant({
    variants: {
      default: {
        background: colors.black,
        "#close-button": {
          color: colors.white,
        },
      },
      light: {
        background: colors.white,
        "#close-button": {
          color: colors.black,
        },
      },
    },
  })
);

ModalHeader.defaultProps = {
  variant: "default",
};

ModalHeader.propTypes = {
  variant: PropTypes.string,
};

export const ModalBody = styled(Flex).attrs(({ bg }) => ({
  bg: bg || "white",
  height: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
}))``;
