import styled from "styled-components";
import { layout, space, typography } from "styled-system";
import React from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { H3, P } from "app/components/Typography";
import Img from "app/components/Img";
import Icon from "app/components/Icon";

// eslint-disable-next-line import/prefer-default-export
export const StyledInput = styled.input`
  color: white;
  text-align: center;
  border: 3px solid ${({ theme, error, value }) =>
    error && !value ? theme.colors.red : theme.colors.monochrome[3]};
  border-radius: 16px;
  background-color: transparent;
  text-transform: uppercase;
  ${layout}
  ${space}
  ${typography}
`;

export const StepBlock = ({ stepNumber, stepText, src }) => (
  <Flex flexDirection="column" mr={3} mb={{ md: 3, _: 0 }}>
    <Flex
      backgroundColor="blue"
      alignItems="center"
      borderTopLeftRadius="8px"
      borderTopRightRadius="8px"
    >
      <Div
        m={2}
        p={2}
        backgroundColor="monochrome.8"
        border="1px solid"
        borderColor="monochrome.8"
        borderRadius="2px"
      >
        <P
          fontSize={0}
          fontWeight="bold"
          color="monochrome.0"
          letterSpacing="0.1em"
        >
          Step {stepNumber}
        </P>
      </Div>
      <H3 color="monochrome.0">{stepText}</H3>
    </Flex>
    <Div>
      <Img src={src} maxWidth="100%" height="auto" width="100%" />
    </Div>
  </Flex>
);

StepBlock.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepText: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export const FeatureListItem = ({ index, icons, iconMarkup, markup }) => (
  <Flex as="li" alignItems="center" mb={4}>
    <Flex
      alignItems="center"
      justifyContent="center"
      width="30px"
      height="30px"
      borderWidth="3px"
      borderStyle="solid"
      borderRadius="5px"
      mr={3}
      my={3}
    >
      <P fontWeight="bold" fontSize="16px" lineHeight="1" borderColor="white">
        {index}
      </P>
    </Flex>
    <Flex
      alignItems="center"
      height="58px"
      width="100%"
      borderRadius="0px 4px 4px 0px"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        p={2}
        backgroundColor="monochrome.8"
        height="100%"
        maxWidth="88px"
        width="100%"
        borderRadius="4px 0px 0px 4px"
      >
        {icons.map(icon => (
          <Icon as={icon} width="32px" color="monochrome.0" />
        ))}
        {iconMarkup && <P color="monochrome.0">{iconMarkup}</P>}
      </Flex>
      <Flex
        as="p"
        alignItems="center"
        p={2}
        backgroundColor="monochrome.1"
        height="100%"
        width="100%"
        borderRadius="0px 4px 4px 0px"
      >
        {markup}
      </Flex>
    </Flex>
  </Flex>
);

FeatureListItem.defaultProps = {
  iconMarkup: null,
};

FeatureListItem.propTypes = {
  index: PropTypes.number.isRequired,
  icons: PropTypes.string.isRequired,
  iconMarkup: PropTypes.string,
  markup: PropTypes.string.isRequired,
};
